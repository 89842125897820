.root {
  /* SectionContainer defaults */
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  /* Hero specific */
  min-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.badge {
   position: absolute;
   left: -8px;
   top: 25px;
   background-color: #f37c32;
   -webkit-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
   -moz-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
   -o-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
   -ms-transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
   transform: rotate(-45deg) scale(1) skew(0deg) translate(10px);
   text-align: center;
   color: white;
   padding: 0 .5em;
   border-top: 0px;
   line-height: 20px;
   margin: 0;
 }

.badge:after {
  content: ' ';
  position: absolute;
  right: -20px;
  top: 0px;
  border-right: 20px solid transparent;
  border-bottom: 20px solid #f37c32;
}

.badge:before {
  content: ' ';
  position: absolute;
  left: -20px;
  top: 0;
  border-left: 20px solid transparent;
  border-bottom: 20px solid #f37c32;
}

.button_hero_container {
  display: flex;
  justify-content: space-evenly;
}

.button_hero {
  background: linear-gradient(145deg, #e29000, #ffd000);
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 15px rgba(226, 144, 6, 0.6), 0 0 10px rgba(226, 144, 6, 0.4), 0 0 20px rgba(226, 144, 6, 0.2);
  color: white;
  cursor: pointer;
  font-size: 18px;
  padding: 10px 20px;
  text-align: center;
  text-transform: uppercase;
  background-size: 200% 200%;
  background-position: 0% 50%;
  transition: transform 0.2s, box-shadow 0.2s, background-position 1s;
}

.button_hero:hover {
  box-shadow: 0 6px 25px rgba(226, 144, 6, 0.8), 0 0 20px rgba(226, 144, 6, 0.6), 0 0 30px rgba(226, 144, 6, 0.4);
  transform: scale(1.05);
  background-position: 100% 50%;
}