@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: row;
  overflow-x: auto;
}

.tab {
  margin-left: 16px;

  &:first-child {
    margin-left: 0;
  }

  @media (--viewportLarge) {
    margin-left: 24px;
  }
}

.link {
  display: inline-block;
  white-space: nowrap;

  /* Font */
  composes: textSmall from global;

  color: var(--colorGrey300);
  padding-bottom: 10px;
  padding-top: 10px;
  height: 100%;

  /* push tabs against bottom of tab bar */
  margin-top: auto;

  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;

  transition: var(--transitionStyleButton);
  background-position: calc(var(--TabNav_linkWidth) + 4px) center; /* SelectedLink's width (200px) + border thickness (4px) = 204px */
  position: relative; /* Needed for link::after */
  
  &:hover {
    text-decoration: none;
    color: var(--marketplaceColorLight50);
  }

  @media (--viewportLarge) {
    font-weight: var(--fontWeightSemiBold);
    font-size: 20px;
    line-height: 24px;

    height: unset;
    width: var(--TabNav_linkWidth);
    border-bottom-width: 0px;
    margin-top: 0;
    margin-bottom: 16px;

    padding-top: 5px; /* Drop the text to adjust it to correct baseline */
    padding-bottom: 3px;
  }
}

.link:hover::after {
  @media (--viewportLarge){
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 4px;
    height: 100%;
    background: var(--marketplaceColorLight50);
    /* try to transition this in on creation? */
  }
}

.selectedLink {
  border-bottom-color: var(--colorGrey100);
  color: var(--colorWhite);

  @media (--viewportLarge) {
    position: relative;
  }
}

.selectedLink::after {
  @media (--viewportLarge){
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 4px;
    height: 100%;
    background: var(--colorWhite);
  }
}

.selectedLink:hover::after {
  @media (--viewportLarge){
    background: var(--marketplaceColorLight50);
    transition: var(--transitionStyleButton);
  }
}

.disabled {
  pointer-events: none;
  color: var(--colorGrey300);
  text-decoration: none;
}
