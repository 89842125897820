.root {
  margin-top: 15px;
}

input, input:focus, textarea, textarea:focus, select, select:focus {
  background-color: var(--marketplaceColorDark);
  color: var(--colorWhite) !important;
  opacity: 0.70;
}

.inputError {
  border: var(--borderErrorField);
}
