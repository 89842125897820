@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--marketplaceColor);
  }

  &:target {
    scroll-margin-top: var(--topbarHeight);

    @media (--viewportMedium) {
      scroll-margin-top: var(--topbarHeightDesktop);
    }
  }
}

.sectionContent {
  padding: 32px 0;
  position: relative;
  word-break: break-word;
  background-color: var(--marketplaceColor);

  @media (--viewportMedium) {
    padding: 32px 0;
  }
}

.sectionContentIsLandingHero {
  position: absolute;
  top: 0px;
  background-color: var(--marketplaceColor);
  box-shadow: inset 0px 2px 100px rgba(0, 0, 0, 0.5);
  max-width: 800px;
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}